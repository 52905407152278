<template>
  <div style="max-width:100%; width: 100%;height:100%;overflow-x: auto;" class="d-flex flex-column py-3">
    <LeftTopPanelTitle text="Self-Exclusion" icon="user-slash" />
    <div
      style="width:100%;color: var(--pr-color);font-size: 0.9rem;"
      class="px-3 mt-3"
    >
      <div>If you wish to take a break, you will be denied access to this site for the next 30 days.</div>
      <div class="mt-3">
        You will be sent full details in an email.
      </div>
      <div class="mt-3">
        Note that upon comfirming you will also be automatically logged out.
      </div>
    </div>
    <div
      class="d-flex"
      style="margin-top:15px;margin-left:-5px;color: var(--pr-color);margin-bottom: 20px;;"
      @click="toggleAgree()"
    >
      <mdb-input
        v-model="agree"
        type="checkbox"
        auto-complete="ex-agr"
        size="sm"
        class="m-0"
      />
      <div class="text-white" style="margin-top:0px;font-size: 0.9rem;">
        I confirm I wish to take a break.
      </div>
    </div>
    <div class="d-flex" style="margin-left:15px;">
      <!-- AGREE BUTTON -->
      <mdb-btn
        v-if="updated === false"
        v-show="!buttonClick"
        :disabled="agree === false"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
        @click="takeABreak()"
      >
        CONFIRM
      </mdb-btn>
      <!-- SPINNER -->
      <mdb-btn
        v-if="updated === false"
        v-show="buttonClick"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
      <!-- TICK -->
      <mdb-btn
        v-if="updated === true"
        class="btn primary-btn btn-radius m-0"
        style="width:110px;margin:0px !important;font-size:0.8rem !important;padding: 0.5rem 1.6rem;"
      >
        <mdb-icon icon="fa fa-check" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userService from '@/api-services/user.service'
import LeftTopPanelTitle from '@/components/leftTopPanelTitle.vue'

export default {
  name: 'SelfExclusion',
  components: {
    LeftTopPanelTitle
  },
  data () {
    return {
      agree: false,
      buttonClick: false,
      updated: false
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'mobileView'
    ])
  },
  methods: {
    toggleAgree () {
      if (this.agree === true) {
        this.agree = false
      } else {
        this.agree = true
      }
    },
    takeABreak () {
      this.buttonClick = true
      userService.takeABreak(this.userData.access_token, this.userData.une).then((res) => {
        console.log(res.data)
        this.agree = false
        this.updated = true
        setTimeout(() => {
          this.updated = false
          this.buttonClick = false
          setTimeout(() => {
            this.show = false
            this.username = ''
            this.password = ''
            this.$store.commit('storeUserData', { aut: false })
            this.$store.commit('storeId', 0)
            this.$store.commit('storeHero', 0)
            this.$store.commit('storeSport', 0)
            this.$store.commit('storeColour', '#e1e1e1')
            this.$store.commit('storeNumber', 0)
            this.$store.commit('storeFirstInitial', '')
            this.$store.commit('storeSecondInitial', '')
            this.$store.commit('clubUrl', '')
            this.$store.commit('clearBasket')
            this.$store.commit('storeLoginData', true)
            this.$router.push({ path: '/play', query: { page: 1 } }).catch(() => {})
          }, 3000)
        }, 3000)
      }).catch(() => {
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true })
      })
      this.buttonClick = false
    }
  }
}
</script>
